"use client"

import {
    IFixedDownloadButtonProps,
    IHeaderComponentProps
} from "../../../lib/types/components";
import {publishWithUsType, sendPublishWithUsEvent} from "../../../lib/utils/constants/AnalyticsConstants";
import {
    GDC_HEADER_TYPE,
    GDC_WEB_URL,
    GET_APP_URL,
    HEADER_DOWNLOAD_BUTTON_TYPE} from "../../../lib/utils/constants/GlobalConstants";
import {checkIsGetAppAF, checkIsGetAppFb, showToggle} from '../../../lib/utils/constants/PagesConstants';
import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import FixedDownloadButtonV2 from "../FixedDownloadButton/FixedDownloadButtonV2";
import GDCSubmitButton from "../FixedDownloadButton/GDCSubmitButton";
import HamburgerComponent from "../Hamburger/HamburgerComponent";
import LanguageToggleComponent from "../LanguageToggle/LanguageToggleComponent";
import React from "react";
import styles from "./HeaderComponentV2.module.css"
import useIsMobile from "../../../lib/customHooks/isMobile";
import {getLogoUrl, multipleClassName} from "../../../lib/utils/helpers/helper";
import {
    GAMESMANIA_DOT_IN,
    getWebsiteEnvironment,
    WINFANTASY_DOT_IN, WINZO_DOT_APP, WINZO_GLOBAL_URL,
    WINZO_JOGOS_DOT_COM_BR
} from "../../../lib/utils/constants/EnvironmentConstants";
import { useDownloadContext } from "../../../lib/context/CommonContext";
import { LANGUAGE_COUNTRY } from "../../../lib/utils/constants/LocaleAndCountryConstants";
import HeaderVersionToggle from "../../BTTC3/HeaderVersionToggle/HeaderVersionToggle";
import Analytics from "../../../lib/analytics/Analytics";
import { getGDCPageEvents } from "../../../lib/analytics/AnalyticsEvents";

const sendAnalyticsGDCHedaer = () => {
    Analytics.getInstance().logEvent(getGDCPageEvents('Header_Submit_Clicked'));
}

const HeaderComponentV2 = (props: IHeaderComponentProps) => {
    const {isMobileDevice} = useIsMobile()
    const {menuOpen} = useDownloadContext()

    const {
        specialData,
        fixedDownloadButtonData,
        hideDownloadButton,
        isUtmHotstar,
        languageToggleData,
        locale,
        headerType,
        showSubmitButton,
        gdcFormUrl,
        gdcButtonText,
        selectLanguageText,
        pageName
    } = props;

    let showLanguageToggle = showToggle()
    if (locale === LANGUAGE_COUNTRY.PT_BR && getWebsiteEnvironment().baseUrl === WINZO_GLOBAL_URL) {
        showLanguageToggle = false
    } else if (locale === LANGUAGE_COUNTRY.EN_IN && getWebsiteEnvironment().baseUrl === WINZO_DOT_APP) {
        showLanguageToggle = false
    }

    const hideHamburger: boolean = props.hideHamburger || (pageName === GET_APP_URL || checkIsGetAppAF(pageName) || checkIsGetAppFb(pageName));

    const fixedDownloadBtnProps: IFixedDownloadButtonProps = {
        type: HEADER_DOWNLOAD_BUTTON_TYPE,
        fixedDownloadButtonData: fixedDownloadButtonData,
        pageName: pageName
    }

    const toggleLanguageProps = {
        languageToggleData: languageToggleData,
        locale: locale,
        selectLanguageText
    }

    const isGetApp = pageName === GET_APP_URL

    return (
        fixedDownloadButtonData ?
            <>
                <header className={styles.header} style={{
                    display: [WINFANTASY_DOT_IN, GAMESMANIA_DOT_IN].includes(getWebsiteEnvironment().baseUrl) && pageName === GET_APP_URL ? "none" : "flex"
                }}>
                    <div className={"container"}>
                        <nav className={multipleClassName("nav", styles.nav)}>
                            <div className={styles.logoContainer}>
                                <div className={styles.logo}>
                                    {
                                        (pageName && pageName.includes(GET_APP_URL)) ? (
                                            <CustomImageComponent
                                                layout={"fill"}
                                                objectFit={"contain"}
                                                src={getLogoUrl(locale)}
                                                alt="winzo games logo"
                                                priority={true}
                                            />
                                        ) : (
                                            headerType === GDC_HEADER_TYPE ?
                                                <a href={"https://console.winzogames.com/"}>
                                                    <CustomImageComponent
                                                        layout={"fill"}
                                                        objectFit={"contain"}
                                                        src={getLogoUrl(locale)}
                                                        alt="winzo games logo"
                                                        priority={true}
                                                    />
                                                </a>
                                                :
                                                <CustomLinkComponent href={"/"}>
                                                    <CustomImageComponent
                                                        layout={"fill"}
                                                        objectFit={"contain"}
                                                        src={getLogoUrl(locale)}
                                                        alt="winzo games logo"
                                                        priority={true}
                                                    />
                                                </CustomLinkComponent>
                                        )
                                    }
                                </div>
                                <div className={multipleClassName("languageToggleGlobal",styles.languageToggle)}>
                                    {showLanguageToggle && languageToggleData &&
                                        <LanguageToggleComponent {...toggleLanguageProps} />}
                                </div>
                            </div>


                            <div className={styles.navItemsContainer}>
                                <div className={styles.navItems}
                                     style={typeof isMobileDevice === "undefined" || isMobileDevice
                                         ? {display: `${headerType === GDC_HEADER_TYPE ? "block" : "none"}`}
                                         : {}}>
                                    <ul>
                                        {

                                            (!menuOpen && !isGetApp && specialData) && specialData.map((item, index) => {
                                                    if (item.dropdown) {
                                                        return (
                                                            <li key={"specialData_" + index}>
                                                                <div className={styles.dropdown} key={index}>
                                                                    <div className={styles.dropbtn}>{item.title}</div>
                                                                    <div className={styles.dropdownContent}>
                                                                        {item.dropdown.map((drop, i) => {
                                                                            if(getWebsiteEnvironment().baseUrl === WINZO_JOGOS_DOT_COM_BR
                                                                                && drop.link === "/pt-br/blog/"){
                                                                                    drop.link = "/blog/"
                                                                                }
                                                                            return <a href={drop.link}
                                                                                      key={"item.dropdown_" + i}>{drop.title}</a>
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                    if (item.special && !item.dropdown) {
                                                        if (item.isButtonType) {
                                                            return (
                                                                <a
                                                                    href={
                                                                        item.link
                                                                    }
                                                                >
                                                                    <button
                                                                        className={
                                                                            styles.season1
                                                                        }
                                                                    >
                                                                        {
                                                                            item.title
                                                                        }
                                                                    </button>
                                                                </a>
                                                            );
                                                        }
                                                        return (
                                                            <li key={index} className={styles.greenBackground}>
                                                                {
                                                                    item.outside
                                                                        ?
                                                                        <a href={item.link} target="_blank" rel={"noreferrer"}
                                                                           onClick={() => {
                                                                               item.link === GDC_WEB_URL && sendPublishWithUsEvent(publishWithUsType.DESKTOP)
                                                                           }}>
                                                                            <span>{item.title}</span>
                                                                        </a>
                                                                        :
                                                                        <CustomLinkComponent
                                                                            href={item.link}
                                                                            mobStyle={{
                                                                                textDecoration: "none"
                                                                            }}
                                                                            desktopStyle={{
                                                                                textDecoration: "none"
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                {item.title}
                                                                            </span>
                                                                        </CustomLinkComponent>
                                                                }
                                                            </li>
                                                        )

                                                    }
                                                    return <></>
                                                }
                                            )
                                        }
                                    </ul>
                                </div>
                                {
                                    hideDownloadButton
                                        ? <></>
                                        : <FixedDownloadButtonV2 {...fixedDownloadBtnProps} />
                                }
                                {headerType === GDC_HEADER_TYPE && props.seasonToggleData && !isMobileDevice &&
                                    <HeaderVersionToggle {...props.seasonToggleData!}/>
                                }
                                {headerType === GDC_HEADER_TYPE && showSubmitButton ? (
                                    <GDCSubmitButton
                                        text={gdcButtonText}
                                        url={gdcFormUrl}
                                        type={HEADER_DOWNLOAD_BUTTON_TYPE}
                                        sendAnalytics={sendAnalyticsGDCHedaer}
                                    />
                                ) : (
                                    <></>
                                )}
                                {
                                    !hideHamburger && <HamburgerComponent />
                                }
                            </div>
                        </nav>
                    </div>
                </header>
            </> : <></>
    )
}

export default HeaderComponentV2;